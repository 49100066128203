import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ApolloProvider } from '@apollo/client';
import { Toaster, toast } from 'react-hot-toast';
import TagManager from 'react-gtm-module';
import Cookies from 'universal-cookie';
import { i18n } from '@spacefill/shared/src/i18n/I18n';
import { UserProvider } from '@spacefill/shared/src/utils/UserContext';
import { BeamerProvider } from '@spacefill/shared/src/utils/Beamer';
import { TopBannerContextProvider } from '@spacefill/shared/src/utils/TopBannerContext';
import { ThemeStateProvider } from '@spacefill/shared/src/utils/ThemeStateContext';
import { overrideConsoleErrorToAddSentryCapture } from '@spacefill/shared/src/utils/OverrideConsole';
import { FeatureTogglingProvider, FeatureTogglingToolbar } from '@spacefill/shared/src/utils/FeatureToggling';
import { WarehouseNamingContextProvider } from '@spacefill/shared/src/utils/WarehouseNamingContext';
import { SpacefillThemeProvider } from '@spacefill/shared/src/components/Theme';
import { client as ApolloClient, pingGraphQLServer } from '@spacefill/shared/src/utils/Apollo';
import '@spacefill/shared/src/utils/YupMethods';
import { datadogRum } from '@datadog/browser-rum';

import { featureSettings } from './Features';
import { RouterContext } from './router/RouterContext';
import '@spacefill/uikit/src/index.css';

const cookies = new Cookies();
const sentryDSN = window?._spacefill_env_?.sentryDsn || import.meta.env.VITE_EXTERNAL_CONSOLE_SENTRY_DSN;
const sentryEnvironment =
    window?._spacefill_env_?.sentryEnvironment || import.meta.env.VITE_EXTERNAL_CONSOLE_SENTRY_ENVIRONMENT;
const googletagManagerId =
    window?._spacefill_env_?.googleTagManagerId || import.meta.env.VITE_EXTERNAL_CONSOLE_GOOGLE_TAG_MANAGER_ID;
const googleTagManagerAuth =
    window?._spacefill_env_?.googleTagManagerAuth || import.meta.env.VITE_EXTERNAL_CONSOLE_GOOGLE_TAG_MANAGER_AUTH;
const googletagManagerEnv =
    window?._spacefill_env_?.googleTagManagerEnv || import.meta.env.VITE_EXTERNAL_CONSOLE_GOOGLE_TAG_MANAGER_ENV;
const datadogApplicationId =
    window?._spacefill_env_?.datadogApplicationId || import.meta.env?.VITE_EXTERNAL_CONSOLE_DATADOG_APPLICATION_ID;
const datadogClientToken =
    window?._spacefill_env_?.datadogClientToken || import.meta.env?.VITE_EXTERNAL_CONSOLE_DATADOG_CLIENT_TOKEN;
const datadogVersion =
    window?._spacefill_env_?.datadogVersion || import.meta.env?.VITE_EXTERNAL_CONSOLE_DATADOG_VERSION;

if (typeof sentryDSN === 'string' && sentryDSN !== '') {
    console.info('Sentry enable');
    Sentry.init({
        dsn: sentryDSN,
        release: import.meta.env.VITE_EXTERNAL_CONSOLE_SENTRY_RELEASE,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
        integrations: [
            new ExtraErrorData({
                depth: 11,
            }),
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 0.5,
        normalizeDepth: 11,
        environment: sentryEnvironment || 'unknown',
    });
    overrideConsoleErrorToAddSentryCapture();
}
if (datadogApplicationId) {
    datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.eu',
        service: 'external-console',
        env: import.meta.env.VITE_EXTERNAL_CONSOLE_SENTRY_ENVIRONMENT,

        version: datadogVersion,
        allowedTracingUrls: [(url) => url.includes('spacefill.fr')],
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
}

if (googletagManagerId && googletagManagerId !== '') {
    TagManager.initialize({
        gtmId: googletagManagerId,
        auth: googleTagManagerAuth,
        preview: googletagManagerEnv,
    });
}

const enableMockTesting = new URLSearchParams(window.location.search).get('enableMockTesting');
if (enableMockTesting) {
    localStorage.setItem('enableMockTesting', '1');
}

if (window.navigator.userAgent === 'puppeteer') {
    window.toast = toast;
}

const queryClient = new QueryClient();

const App = () => {
    // Need to put it here because Apollo rerender at login and when in
    // impersonate mode it remove cookies before user is connected
    if (new URL(window.location.href).searchParams.get('impersonateUserId')) {
        cookies.remove('jwtTokenExternalConsole', {
            domain: document.domain,
            path: '/',
        });
        cookies.remove('jwtTokenExternalConsoleImpersonator', {
            domain: document.domain,
            path: '/',
        });
        cookies.remove('jwtTokenRememberMe', {
            domain: document.domain,
            path: '/',
        });
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ApolloProvider client={ApolloClient}>
                <TopBannerContextProvider>
                    <UserProvider>
                        <BeamerProvider>
                            <FeatureTogglingProvider featureSettings={featureSettings}>
                                <ThemeStateProvider>
                                    <SpacefillThemeProvider>
                                        <WarehouseNamingContextProvider>
                                            <RouterContext />
                                            <FeatureTogglingToolbar />
                                            <Toaster />
                                        </WarehouseNamingContextProvider>
                                    </SpacefillThemeProvider>
                                </ThemeStateProvider>
                            </FeatureTogglingProvider>
                        </BeamerProvider>
                    </UserProvider>
                </TopBannerContextProvider>
            </ApolloProvider>
            {import.meta.env?.VITE_EXTERNAL_CONSOLE_REACT_QUERY_DEVTOOLS_ENABLED && (
                <ReactQueryDevtools initialIsOpen />
            )}
        </QueryClientProvider>
    );
};

pingGraphQLServer((error) => {
    toast.error(i18n.t('Internal error!'));
    console.error(error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
