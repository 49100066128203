import { HTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';

export type CommonTypes = {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'outlined' | 'danger' | 'info' | 'success' | 'warning';
    small?: boolean;
    children?: ReactNode;
    iconStart?: ReactNode;
    iconEnd?: ReactNode;
    dataTestid?: string;
    className?: string;
};

export type ButtonTypes = CommonTypes & {
    type?: 'button';
    onClick: MouseEventHandler;
    disabled?: boolean;
    htmlAttributes?: HTMLAttributes<HTMLButtonElement>;
};

type ButtonSubmitTypes = CommonTypes & {
    type?: 'submit';
    onClick?: MouseEventHandler;
    disabled?: boolean;
    htmlAttributes?: HTMLAttributes<HTMLButtonElement>;
};

type LinkTypes = CommonTypes & {
    type?: 'link';
    to: string;
    htmlAttributes?: HTMLAttributes<HTMLAnchorElement>;
};

type ExternalLinkTypes = CommonTypes & {
    type?: 'external-link';
    href: string;
    htmlAttributes?: HTMLAttributes<HTMLAnchorElement>;
};

export type ButtonProps = ButtonTypes | ButtonSubmitTypes | LinkTypes | ExternalLinkTypes;

export const Button = (props: ButtonProps) => {
    // Note : I did used props destructuration to work around a type error on `onClick`, `to` and `href` props, not defined depending on `type`

    const variant = props.variant || 'primary';
    const type = props.type || 'button';
    const small = props.small || false;

    const classNames = clsx(
        // Common styles
        [
            'text-sm',
            'rounded-md',
            small ? 'px-2 py-1.5' : 'px-5 py-2',
            'gap-2',
            'flex',
            'whitespace-nowrap',
            'items-center',
            'focus:outline',
            'focus:outline-2',
            'disabled:text-gray-400',
            'disabled:cursor-not-allowed',
            props.className,
        ],
        // Variant styles
        {
            'bg-lime-300 text-black hover:bg-lime-400 active:bg-lime-500 focus:outline-focus-primary disabled:bg-lime-100':
                variant === 'primary',
            'bg-gray-200 hover:bg-gray-300 active:bg-gray-400 focus:outline-focus-primary text-black disabled:bg-gray-100':
                variant === 'secondary',
            'bg-violet-200 hover:bg-violet-300 active:bg-violet-400 focus:outline-focus-primary text-black disabled:bg-violet-100':
                variant === 'tertiary',
            'bg-white hover:bg-gray-100 active:bg-gray-200 focus:outline-focus-secondary text-black border border-solid border-gray-300 disabled:bg-gray-100':
                variant === 'outlined',
            'bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-focus-primary text-white disabled:bg-red-100 disabled:text-white':
                variant === 'danger',
            'bg-blue-500 hover:bg-blue-600 active:bg-blue-700 focus:outline-focus-primary text-white disabled:bg-blue-100 disabled:text-white':
                variant === 'info',
            'bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-700 focus:outline-focus-primary text-white disabled:bg-emerald-100 disabled:text-white':
                variant === 'success',
            'bg-orange-500 hover:bg-orange-600 active:bg-orange-700 focus:outline-focus-primary text-white disabled:bg-orange-100 disabled:text-white':
                variant === 'warning',
        }
    );

    if (!type || type === 'button') {
        const { onClick, disabled, htmlAttributes } = props as ButtonTypes;
        return (
            <button
                type='button'
                disabled={disabled}
                aria-disabled={disabled}
                onClick={onClick}
                data-testid={props.dataTestid}
                className={classNames}
                {...htmlAttributes}
            >
                {props.iconStart}
                {props.children}
                {props.iconEnd}
            </button>
        );
    } else if (type === 'submit') {
        const { htmlAttributes, disabled, onClick } = props as ButtonSubmitTypes;
        return (
            <button
                type='submit'
                disabled={disabled}
                aria-disabled={disabled}
                data-testid={props.dataTestid}
                className={classNames}
                onClick={onClick}
                {...htmlAttributes}
            >
                {props.iconStart}
                {props.children}
                {props.iconEnd}
            </button>
        );
    } else if (type === 'link') {
        const { to, htmlAttributes } = props as LinkTypes;
        return (
            <Link
                to={to}
                data-testid={props.dataTestid}
                className={classNames}
                {...htmlAttributes}
            >
                {props.iconStart}
                {props.children}
                {props.iconEnd}
            </Link>
        );
    } else if (type === 'external-link') {
        const { href, htmlAttributes } = props as ExternalLinkTypes;
        return (
            <a
                href={href}
                data-testid={props.dataTestid}
                className={classNames}
                {...htmlAttributes}
            >
                {props.iconStart}
                {props.children}
                {props.iconEnd}
            </a>
        );
    }
    return null;
};
