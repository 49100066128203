import { HTMLAttributes } from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface ChipIconProps {
    color?: 'gray' | 'blue' | 'red' | 'orange';
    icon: IconDefinition;
    hasBadge?: boolean;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
}

export const ChipIcon = ({ color = 'gray', icon, hasBadge = false, dataTestid, htmlAttributes }: ChipIconProps) => (
    <div
        className={clsx(
            // Common styles
            ['text-sm', 'rounded', 'inline-block', 'p-1', 'leading-3'],
            // Color variants
            {
                'bg-blue-100 text-blue-600 hover:bg-blue-200 hover:text-blue-600': color === 'blue',
                'bg-red-100 text-red-600 hover:bg-red-200 hover:text-red-600': color === 'red',
                'bg-gray-200 text-gray-600 hover:bg-gray-300 hover:text-gray-800': color === 'gray',
                'bg-orange-100 text-orange-500 hover:bg-orange-200 hover:text-orange-700': color === 'orange',
            },
            // Badge specific
            { relative: hasBadge }
        )}
        data-testid={dataTestid}
        {...htmlAttributes}
    >
        {hasBadge && (
            <div
                data-after=''
                className={clsx([
                    'absolute -right-1 -top-1',
                    'h-2 w-2',
                    'bg-red-500',
                    'rounded-full border border-solid border-white',
                    'after:content-[attr(data-after)]',
                ])}
            />
        )}
        <FontAwesomeIcon icon={icon} />
    </div>
);
