import {
    ApolloClient,
    InMemoryCache,
    ApolloLink,
    gql,
    from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'universal-cookie';
import omitDeep from 'omit-deep-lodash';
import { getMainDefinition } from '@apollo/client/utilities';

import {
    getEnvironmentVariable
} from './GetEnvVar';

const cookies = new Cookies();

const graphqlApiUrl = getEnvironmentVariable('graphqlApiUrl');
const apolloDevtoolsEnabled = getEnvironmentVariable('apolloDevtoolsEnabled');

const authLink = new ApolloLink((operation, forward) => {
    if (operation?.getContext()?.customerNoAuth) {
        return forward(operation);
    }

    const envPrefix = getEnvironmentVariable('envPrefix');

    let jwtAccessNameCookie;
    switch (envPrefix) {
    case 'ADMIN-CONSOLE':
        jwtAccessNameCookie = 'jwtTokenAdminConsole';
        break;
    case 'EXTERNAL-CONSOLE':
        jwtAccessNameCookie = 'jwtTokenExternalConsole';
        break;
    default:
        break;
    }

    operation.setContext({
        headers: {
            authorization: `Bearer ${cookies.get(jwtAccessNameCookie)}`
        }
    });

    return forward(operation);
});

const logoutOnJwtExpiredLink = onError(({ graphQLErrors }) => {
    (graphQLErrors ?? []).forEach(({ message }) => {
        if (message === 'jwt expired' || message === 'jwt malformed') {
            window.location.assign('/logout');
        }
    });
});

const keysToOmit = ['__typename'];
const keysToOmitLink = new ApolloLink((operation, forward) => {
    const def = getMainDefinition(operation.query);
    if (def && def.operation === 'mutation') {
        operation.variables = omitDeep(operation.variables, keysToOmit);
    }

    return forward(operation);
});

const client = new ApolloClient({
    link: from([
        logoutOnJwtExpiredLink,
        authLink,
        keysToOmitLink,
        createUploadLink({
            uri: graphqlApiUrl
        })
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore'
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        }
    },
    connectToDevTools: apolloDevtoolsEnabled
});

// do not delete for some unknown reason it prevents the front to unlimitated retry to query the backend when there is an error
const pingGraphQLServer = (callbackError) => {
    client
        .query({
            query: gql`query isBackendAvailable {__typename}`,
            context: {
                customerNoAuth: true
            }
        })
        .catch(callbackError);
};

export {
    client,
    pingGraphQLServer
};
