const getWarehouseLabelFromConfiguration = (user, customerWarehouseConfiguration) => {
    if (user?.role === 'SHIPPER' && customerWarehouseConfiguration?.warehouseNameForShipper) {
        return customerWarehouseConfiguration?.warehouseNameForShipper;
    }
    if (user?.role === 'PROVIDER' && customerWarehouseConfiguration?.warehouse?.reference) {
        return customerWarehouseConfiguration?.warehouse?.reference;
    }
    return `${customerWarehouseConfiguration?.warehouse?.address?.city} - ${customerWarehouseConfiguration?.warehouse?.address?.zipCode}`;
};

const getWarehouseLabel = (user, warehouse, defaultMessage) => {
    if (!warehouse) {
        return defaultMessage;
    }

    const customerWarehouseConfiguration = user?.customerWarehouseConfigurationsWithLogisticManagement?.find((node) => node?.warehouseId === warehouse?.id);
    if (user?.role === 'SHIPPER' && customerWarehouseConfiguration?.warehouseNameForShipper) {
        return customerWarehouseConfiguration?.warehouseNameForShipper;
    }

    if (user?.role === 'PROVIDER' && warehouse.reference) {
        return warehouse.reference;
    }

    if (!warehouse.address) {
        return defaultMessage;
    }

    const { city, zipCode } = warehouse.address;
    return [city, zipCode].filter((value) => !!value).join(' - ');
};

const getWarehouseLabelByWarehouseId = (user, warehouseId, defaultMessage) => {
    if (!warehouseId) {
        return defaultMessage;
    }

    const customerWarehouseConfiguration = user?.customerWarehouseConfigurationsWithLogisticManagement?.find((node) => node?.warehouseId === warehouseId);
    if (user?.role === 'SHIPPER' && customerWarehouseConfiguration?.warehouseNameForShipper) {
        return customerWarehouseConfiguration?.warehouseNameForShipper;
    }

    if (user?.role === 'PROVIDER' && customerWarehouseConfiguration?.warehouse?.reference) {
        return customerWarehouseConfiguration.warehouse.reference;
    }

    if (!customerWarehouseConfiguration?.warehouse?.address) {
        return defaultMessage;
    }

    const { city, zipCode } = customerWarehouseConfiguration.warehouse.address;
    return [city, zipCode].filter((value) => !!value).join('-');
};

const getWarehouseAddress = (warehouse, defaultMessage) => {
    return `${(warehouse?.address?.addressLine1 ?? defaultMessage)}, ${(warehouse?.address?.zipCode ?? defaultMessage)} ${(warehouse?.address?.city ?? defaultMessage)}`;
};

export {
    getWarehouseLabelFromConfiguration,
    getWarehouseLabel,
    getWarehouseLabelByWarehouseId,
    getWarehouseAddress
};
