import { type HTMLAttributes, type ReactNode } from 'react';
import type { TFunction } from 'i18next';
import { useTranslation, Trans } from 'react-i18next';
import { Transition, Dialog as HeadlessUiDialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faAwardSimple } from '@fortawesome/pro-light-svg-icons';

import { Button } from '../../../components/Button';

import AnalyticsPictureEn from './assets/en/analytics.svg';
import AnalyticsPictureDe from './assets/de/analytics.svg';
import AnalyticsPictureFr from './assets/fr/analytics.svg';
import OrderManagementPictureEn from './assets/en/order_management.svg';
import OrderManagementPictureDe from './assets/de/order_management.svg';
import OrderManagementPictureFr from './assets/fr/order_management.svg';
import IncidentPicture from './assets/module_incident.png';

type ModalContent = Record<
    'analytics' | 'order_management_system' | 'incident',
    {
        tagline: string;
        title: string;
        topContent: ReactNode;
        bottomContent: ReactNode;
        picture: {
            FR: string;
            EN: string;
            DE: string;
        };
        contactButtonText: string;
        contactButtonUrl?: string;
    }
>;

type User = {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    preferredLanguage: string;
};

const premiumAccessModalData = (type: keyof ModalContent, user: User, t: TFunction, language: string) => {
    const content = {
        analytics: {
            tagline: t('Analytics'),
            title: t('Customize and turn your data analytics into actions'),
            topContent: t(
                'Add a visualization layer to your supply chain for an increased monitoring and transparency of your operations.'
            ),
            bottomContent: (
                <p className='px-1'>
                    {t('Make fast and efficient decisions for your operations thanks to real-time data.')}
                </p>
            ),
            picture: {
                FR: AnalyticsPictureFr,
                EN: AnalyticsPictureEn,
                DE: AnalyticsPictureDe,
            },
            contactButtonText: t('Activate this service'),
            contactButtonUrl: `https://help-spacefill.zendesk.com/hc/${language.toLowerCase()}/requests/new?ticket_form_id=5609478305169&tf_anonymous_requester_email=${encodeURIComponent(
                user?.email
            )}&tf_1900005544053=${
                user?.role === 'SHIPPER' ? 'shipper' : 'partner'
            }&tf_4413256396561=${encodeURIComponent(
                `${user?.firstName} ${user?.lastName}`
            )}&tf_description=${encodeURIComponent(
                t('Could you please activate the "Order Management" service for my organisation ?')
            )}`,
        },
        order_management_system: {
            tagline: t('Order Management'),
            title: t('Take control of your supply chain'),
            topContent: t(
                'Easily manage your activity via Spacefill from the creation of your references catalog to the delivery / reception of your orders.'
            ),
            bottomContent: (
                <div className='px-6'>
                    <p className='mb-6 font-bold'>{t('Thanks to Order management:')}</p>
                    <ul className='mb-6 list-inside list-disc'>
                        <li>{t('Manage your references catalog.')}</li>
                        <li>{t('Place your orders and follow progress in real time.')}</li>
                        <li>{t('Say goodbye to chaotic email and phone exchanges thanks to shared documents.')}</li>
                        <li>{t('Find all relevant info at a glimpse with our intuitive to-do list.')}</li>
                        <li>{t('Simplify your pick-ups and deliveries thanks to the address book.')}</li>
                    </ul>
                </div>
            ),
            picture: {
                FR: OrderManagementPictureFr,
                EN: OrderManagementPictureEn,
                DE: OrderManagementPictureDe,
            },
            contactButtonText: t('Activate Order management'),
            contactButtonUrl: `https://help-spacefill.zendesk.com/hc/${language.toLowerCase()}/requests/new?ticket_form_id=5609478305169&tf_anonymous_requester_email=${encodeURIComponent(
                user?.email
            )}&tf_1900005544053=${
                user?.role === 'SHIPPER' ? 'shipper' : 'partner'
            }&tf_4413256396561=${encodeURIComponent(
                `${user?.firstName} ${user?.lastName}`
            )}&tf_description=${encodeURIComponent(
                t('Could you please activate the "Order Management" service for my organisation ?')
            )}`,
        },
        incident: {
            tagline: t('Incident module'),
            title: t('Efficiently report, track and resolve order anomalies'),
            topContent: t("Discover Spacefill's new premium feature. Contact our team now to activate it."),
            bottomContent: (
                <div className='px-6'>
                    <p className='mb-6 font-bold'>{t('Thanks to the incident module:')}</p>
                    <ul className='mb-6 list-inside list-disc'>
                        <li>
                            <Trans i18nKey='<0>Report any incident,</0> from missing references to damaged quantities or delayed deliveries.'>
                                <span className='m-0 p-0 font-bold'>Report any incident,</span>
                                from missing references to damaged quantities or delayed deliveries.
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey='<0>Notify all stakeholders instantly,</0> send messages, and share documents to provide comprehensive details on the issue.'>
                                <span className='m-0 p-0 font-bold'>Notify all stakeholders instantly,</span>
                                send messages, and share documents to provide comprehensive details on the issue.
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey='<0>Track incident resolution,</0> record incident frequency, and analyze trends over time to increase your operational excellence.'>
                                <span className='m-0 p-0 font-bold'>Track incident resolution,</span>
                                record incident frequency, and analyze trends over time to increase your operational
                                excellence.
                            </Trans>
                        </li>
                    </ul>
                </div>
            ),
            picture: {
                FR: IncidentPicture,
                EN: IncidentPicture,
                DE: IncidentPicture,
            },
            contactButtonText: t('Activate the incident module'),
            contactButtonUrl: `https://help-spacefill.zendesk.com/hc/${((user?.preferredLanguage ?? 'EN') === 'EN'
                ? 'en-us'
                : user.preferredLanguage
            ).toLowerCase()}/requests/new?ticket_form_id=5609478305169&tf_anonymous_requester_email=${encodeURIComponent(
                user?.email
            )}&tf_1900005544053=${
                user?.role === 'SHIPPER' ? 'shipper' : 'partner'
            }&tf_4413256396561=${encodeURIComponent(
                `${user?.firstName} ${user?.lastName}`
            )}&tf_description=${encodeURIComponent(
                t('Could you please activate the "Incidents" service for my organization ?')
            )}`,
        },
    };

    if (type in content) return content[type];
    else console.error(`"${type}" is not in the defined in Modal configuration`);
};

export interface PremiumAccessModalProps {
    isOpen: boolean;
    contentKey: 'analytics' | 'order_management_system' | 'incident';
    featureNameForTracking: string;
    user: User;
    onClose: () => void;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
}

export const PremiumAccessModal = ({
    isOpen,
    contentKey,
    user,
    onClose,
    dataTestid,
    htmlAttributes,
}: PremiumAccessModalProps) => {
    const { t, i18n } = useTranslation();

    const content = premiumAccessModalData(contentKey, user, t, i18n.language);
    if (!content) return null;

    return (
        <Transition show={isOpen}>
            <HeadlessUiDialog
                onClose={onClose}
                className='relative z-40'
                data-testid={dataTestid}
            >
                <Transition.Child
                    enter='duration-300 ease-out'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='duration-300 ease-in'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div
                        className='fixed inset-0 bg-black/70'
                        aria-hidden='true'
                    />
                </Transition.Child>
                <div
                    className='fixed inset-0 flex items-center justify-center p-4'
                    {...htmlAttributes}
                >
                    <Transition.Child
                        enter='transition duration-300 ease-out'
                        enterFrom='transform -translate-y-4 opacity-0'
                        enterTo='transform translate-y-0 opacity-100'
                        leave='transition duration-300 ease-in'
                        leaveFrom='transform translate-y-0 opacity-100'
                        leaveTo='transform -translate-y-4 opacity-0'
                    >
                        <HeadlessUiDialog.Panel className='max-h-144 w-184 overflow-scroll rounded-xl bg-white drop-shadow-md'>
                            <div className='flex justify-end p-4'>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className='h-5 w-5 cursor-pointer text-gray-500'
                                    data-testid='close-button'
                                    onClick={onClose}
                                />
                            </div>
                            <div className='flex flex-col items-center justify-center px-8 pb-6'>
                                <div
                                    className='mb-2 flex items-center gap-1 text-amber-500'
                                    data-testid='premium-access-tagline'
                                >
                                    <FontAwesomeIcon
                                        icon={faAwardSimple}
                                        className='h-5 w-5'
                                        data-testid='premium-access-tagline'
                                    />
                                    {content.tagline}
                                </div>
                                <h3
                                    className='mb-4 text-xl font-bold text-gray-900'
                                    data-testid='premium-access-title'
                                >
                                    {content.title}
                                </h3>
                                <div
                                    className='text-center leading-5'
                                    data-testid='premium-access-top-content'
                                >
                                    {content.topContent}
                                </div>
                                <img
                                    src={content.picture[i18n.language as 'FR' | 'EN' | 'DE']}
                                    className='my-6 rounded-xl'
                                    alt='Premium access content'
                                />
                                <div
                                    className='mb-6 leading-5'
                                    data-testid='premium-access-bottom-content'
                                >
                                    {content.bottomContent}
                                </div>
                                <div className='flex justify-between'>
                                    <a
                                        href={
                                            content.contactButtonUrl ||
                                            'https://help-spacefill.zendesk.com/hc/fr/requests/new'
                                        }
                                        data-testid='contact-button-url'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <Button
                                            dataTestid='contact-button'
                                            onClick={onClose}
                                        >
                                            {content.contactButtonText}
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </HeadlessUiDialog.Panel>
                    </Transition.Child>
                </div>
            </HeadlessUiDialog>
        </Transition>
    );
};
