/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Navigate } from 'react-router-dom';

const LogisticManagementAddressBookEdit = async () => import('../../pages/settings/address-book/Edit').then((module) => ({ Component: module.LogisticManagementAddressBookEdit }));
const LogisticManagementAddressBookNew = async () => import('../../pages/settings/address-book/New').then((module) => ({ Component: module.LogisticManagementAddressBookNew }));
const SettingsViewPage = async () => import('../../pages/settings/View').then((module) => ({ Component: module.SettingViewPage }));
const LogisticManagementCustomFieldsNew = async () => import('../../pages/settings/custom-fields/New').then((module) => ({ Component: module.LogisticManagementCustomFieldsNew }));
const LogisticManagementCustomFieldsEdit = async () => import('../../pages/settings/custom-fields/Edit').then((module) => ({ Component: module.LogisticManagementCustomFieldsEdit }));
const LogisticManagementCarrierNew = async () => import('../../pages/settings/carriers/New').then((module) => ({ Component: module.LogisticManagementCarriersNew }));
const LogisticManagementCarrierEdit = async () => import('../../pages/settings/carriers/Edit').then((module) => ({ Component: module.LogisticManagementCarriersEdit }));

const getSettingsRoutes = (user, _features) => {
    return [
        // users
        {
            path: '/settings/users/',
            lazy: (
                user.role === 'SHIPPER'
                    ? SettingsViewPage
                    : () => <Navigate to='/' />
            ),
            redirectFrom: '/settings/users'
        },
        // address book
        {
            path: '/settings/address-book/',
            lazy: SettingsViewPage,
            condition: (user?.features?.orderManagementEnabled && user?.role === 'SHIPPER')
        },
        {
            path: '/settings/address-book/:customerAddressId/edit',
            lazy: LogisticManagementAddressBookEdit,
            condition: (user?.features?.orderManagementEnabled && user?.role === 'SHIPPER')
        },
        {
            path: '/settings/address-book/new',
            lazy: LogisticManagementAddressBookNew,
            condition: (user?.features?.orderManagementEnabled && user?.role === 'SHIPPER')
        },
        // custom field
        {
            path: '/settings/custom-fields/',
            lazy: SettingsViewPage,
            condition: user?.features?.orderManagementEnabled && user?.role === 'SHIPPER'
        },
        {
            path: '/settings/custom-fields/new',
            lazy: LogisticManagementCustomFieldsNew,
            condition: user?.features?.orderManagementEnabled && user?.role === 'SHIPPER'
        },
        {
            path: '/settings/custom-fields/:customFieldId/edit',
            lazy: LogisticManagementCustomFieldsEdit,
            condition: user?.features?.orderManagementEnabled && user?.role === 'SHIPPER'
        },
        // Carriers page
        {
            path: '/settings/carriers/',
            lazy: SettingsViewPage,
            condition: user?.role === 'SHIPPER'
        },
        {
            path: '/settings/carriers/:carrierId/edit',
            lazy: LogisticManagementCarrierEdit,
            condition: user?.role === 'SHIPPER'
        },
        {
            path: '/settings/carriers/new',
            lazy: LogisticManagementCarrierNew,
            condition: user?.role === 'SHIPPER'
        },
        {
            path: '/settings/*',
            element: <Navigate to='/' />
        }
    ];
};

export { getSettingsRoutes };
