import { useState } from 'react';
import constate from 'constate';

function useTopBanner () {
    const [topBanner, setTopBanner] = useState();
    const hasTopBanner = !!(topBanner?.text);

    return { topBanner, setTopBanner, hasTopBanner };
}

const [TopBannerContextProvider, useTopBannerContext] = constate(useTopBanner);

export {
    TopBannerContextProvider,
    useTopBannerContext
};
