import countries from 'i18n-iso-countries';

Promise.all([
    import('i18n-iso-countries/langs/en.json'),
    import('i18n-iso-countries/langs/de.json'),
    import('i18n-iso-countries/langs/fr.json')
]).then(([en, de, fr]) => {
    countries.registerLocale(en);
    countries.registerLocale(de);
    countries.registerLocale(fr);
});
export const getCountryNameFromCode = (countryCode, language, defaultCountry) => {
    if (!countryCode || !countries.isValid(countryCode)) return defaultCountry;
    return countries.getName(countryCode, language) ?? defaultCountry;
};
