import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

/**
 * Create routes to react-router-dom object format (see https://reactrouter.com/en/main/routers/create-browser-router),
 * from custom js object format :
 * e.g.
   {
        path: '/logistic-management/analytics/',
        lazy: CustomerKeyPerformanceIndicationView,
        // optional layout definition or redirection
        element: <MyCustomLayout>
            <Outlet />
        <MyCustomLayout>,
        condition: user?.features?.hasAnalyticsEnabled,
        conditionFailedRedirect: '/',
        redirectFrom: '/logistic-management/analytics'
    },
    - 'path' and 'lazy' are the same that in react-router-dom. Always use `lazy` to load a page component. To make redirection you can use `element`
    - (optional) 'element' could be used for change the layout, or redirections
    - (optional) 'condition' defines if the route can be added.
    - (optional) 'conditionFailedRedirect' defines the redirect url if the condition fails.
    - (optional) 'redirectFrom' allows to define a redirect route to easily manage the trailing slash case.
 */
const createRoutesFromObject = (objectRoutes, defaultLayout) => {
    const formattedRoutes = [];

    objectRoutes.forEach((route, index) => {
        try {
            if (route?.length === 0) {
                // ignore empty element
                return;
            }
            if (!route.path || (!route.element && !route.lazy)) {
                console.error(route);
                throw new Error(
                    `Invalid route - missing path and element or lazy attributes. Route index ${index}`
                );
            }
            if (!('condition' in route) || route.condition === true) {
                if (route.element && !route.lazy) {
                    formattedRoutes.push({
                        path: route.path,
                        element: route.element
                    });
                } else {
                    formattedRoutes.push({
                        element: route.element || defaultLayout,
                        children: [
                            {
                                path: route.path,
                                lazy: route.lazy
                            }
                        ]
                    });
                }
                if (route?.redirectFrom) {
                    if (route.path === route.redirectFrom) {
                        console.error(route);
                        throw new Error(
                            `Invalid route - 'path' cannot be equal to 'redirectFrom'. Route index ${index}`
                        );
                    }
                    formattedRoutes.push({
                        path: route.redirectFrom,
                        element: (
                            <Navigate to={route?.path}
                                replace
                            />
                        )
                    });
                }
            } else if ('conditionFailedRedirect' in route) {
                formattedRoutes.push({
                    path: route.path,
                    element: (
                        <Navigate to={route?.conditionFailedRedirect}
                            replace
                        />
                    )
                });
            }
        } catch (error) {
            console.error('Invalid route found: ', error);
        }
    });

    return formattedRoutes;
};

const NavigateWithParams = ({ to, ...rest }) => {
    const params = useParams();
    let toValue;
    if (typeof to === 'function') {
        toValue = to(params);
    } else {
        toValue = to;
    }
    return (
        <Navigate to={toValue}
            {...rest}
        />
    );
};

export { createRoutesFromObject, NavigateWithParams };
