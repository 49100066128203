import { useState } from 'react';
import constate from 'constate';

function useWarehouseNamingState () {
    const [warehouseNamingNeedsUpdate, setWarehouseNamingNeedsUpdate] = useState(false);

    return { warehouseNamingNeedsUpdate, setWarehouseNamingNeedsUpdate };
}

const [WarehouseNamingContextProvider, useWarehouseNamingContext] = constate(useWarehouseNamingState);

export {
    WarehouseNamingContextProvider,
    useWarehouseNamingContext
};
