import { HTMLAttributes } from 'react';
import { clsx } from 'clsx';

import { User } from '../../../types/user';
enum AvatarColor {
    lime = 'bg-lime-200',
    emerald = 'bg-emerald-200',
    sky = 'bg-sky-200',
    blue = 'bg-blue-200',
    violet = 'bg-violet-200',
    fuchsia = 'bg-fuchsia-200',
    rose = 'bg-rose-200',
    orange = 'bg-orange-200',
    yellow = 'bg-yellow-200',
    gray = 'bg-gray-100',
}

export interface AvatarProps {
    variant?: keyof typeof AvatarColor;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
    showFullName?: boolean;
    textSize?: 'sm' | 'xs';
    user: User;
    additionalText?: string;
    additionalTextComponent?: React.ReactNode;
    capitalizeAdditionalText?: boolean;
    className?: string;
    additionalTextClassName?: string;
}

function simpleHash(str: string) {
    return str.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
}

function getVariantFromUserId(userId: string | null): keyof typeof AvatarColor {
    const hash = simpleHash(userId || 'noUserId');
    const variantKeys = Object.keys(AvatarColor);
    const index = hash % variantKeys.length;
    return variantKeys[index] as keyof typeof AvatarColor;
}

export const Avatar = ({
    variant,
    dataTestid,
    showFullName = false,
    textSize = 'sm',
    htmlAttributes,
    user,
    additionalText,
    additionalTextComponent,
    capitalizeAdditionalText = true,
    className,
    additionalTextClassName,
}: AvatarProps) => (
    <div
        data-testid={dataTestid}
        className={clsx(
            {
                'flex items-baseline gap-2 font-medium': className === undefined,
                'text-xs text-gray-600': textSize === 'xs',
                'text-sm text-gray-800': textSize === 'sm',
                capitalize: capitalizeAdditionalText,
            },

            className
        )}
    >
        <span
            className={clsx(
                ['flex h-7 w-7 items-center justify-center rounded-full text-xs font-medium uppercase'],
                AvatarColor[variant ? variant : getVariantFromUserId(user?.id)]
            )}
            data-testid={dataTestid ? `${dataTestid}-icon` : ''}
            {...htmlAttributes}
        >
            {user?.firstName?.[0] ?? null}
            {user?.lastName?.[0] ?? null}
        </span>
        {(showFullName || additionalText || additionalTextComponent) && (
            <>
                <p className={clsx(additionalTextClassName)}>
                    {user?.firstName} {user?.lastName}
                </p>
                {additionalText && <p className='text-xs text-gray-500'>{additionalText}</p>}
                {additionalTextComponent}
            </>
        )}
    </div>
);
