import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { useTopBannerContext } from '@spacefill/shared/src/utils/TopBannerContext';
import { Link, Outlet } from 'react-router-dom';
import { clsx } from 'clsx';
import { createPortal } from 'react-dom';

import { SidebarProps } from '../Sidebar';
import { BreadcrumbProps } from '../Breadcrumb';
import { ActionBarProps } from '../ActionBar/ActionBar';

export interface AppLayoutProps {
    pageId?: string;
    dataTestid?: string;
    children: ReactNode[];
    leftNav?: ReactElement<SidebarProps>;
    breadcrumb?: ReactElement<BreadcrumbProps>;
    actionBar?: ReactElement<ActionBarProps>;
}

const AppLayout = ({ pageId, dataTestid, leftNav, breadcrumb }: AppLayoutProps) => {
    const { hasTopBanner, topBanner } = useTopBannerContext();

    return (
        <div
            id='layout-viewport'
            className='relative flex grow flex-col bg-gray-50'
        >
            {leftNav && (
                <aside
                    id='layout-left-side'
                    className='fixed inset-y-0 z-10 w-56' // w-56 Must match with footer
                >
                    {leftNav}
                </aside>
            )}
            <div className={clsx('flex grow flex-col', { 'ml-56': !!leftNav })}>
                <div className='flex grow flex-col'>
                    <header id='layout-header'>
                        {hasTopBanner && (
                            <Link
                                id='notification-drawer'
                                to={topBanner.link || '#'}
                                onClick={typeof topBanner.onClick === 'function' ? topBanner.onClick : undefined}
                                data-testid={topBanner.id}
                                className='flex h-4 items-center justify-center border-none bg-sky-400 py-6 text-sm text-gray-50 no-underline outline-none hover:no-underline focus:no-underline'
                            >
                                {topBanner.text}
                            </Link>
                        )}
                    </header>

                    {breadcrumb && <div>{breadcrumb}</div>}

                    <main
                        id={pageId}
                        data-testid={dataTestid}
                        className='grow px-6 py-8 pb-20'
                    >
                        <Outlet />
                    </main>
                </div>
                <div
                    className='fixed bottom-0 left-56 right-0' // left-56 Must match with sidebar
                    id='app-layout-footer'
                />
            </div>
        </div>
    );
};

const AppLayoutFooter = ({ children }: PropsWithChildren) => {
    const mountPoint = document.getElementById('app-layout-footer');
    if (mountPoint) {
        return createPortal(children, mountPoint);
    }
};

export { AppLayout, AppLayoutFooter };
