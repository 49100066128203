import React, { Fragment, Suspense } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';
import { useFeatureTogglingContext } from '@spacefill/shared/src/utils/FeatureToggling';
import { createRoutesFromObject } from '@spacefill/shared/src/utils/RouterUtils';
import { Loader } from '@spacefill/uikit/src/components/Loader';
import {
    AutoRefresh,
} from '@spacefill/shared/src/utils/AutoRefresh';
import { AppLayout } from '@spacefill/uikit/src/components/AppLayout';

import { SpacefillBreadcrumb } from '../layout/Breadcrumb';
import { ExternalConsoleLeftNav } from '../layout/MultiStorageLeftNav';

import { getLoggedUserRoutes } from './LoggedRouter';
import { getUnloggedUserRoutes } from './UnloggedRouter';

const EnvView = async () =>
    import('@spacefill/shared/src/pages/Env').then((module) => ({
        Component: module.EnvView,
    }));

const RouterContext = () => {
    const { user } = useUserContext();
    const { features } = useFeatureTogglingContext();

    let router;
    const loader = (
        <div className='h-screen w-screen'>
            <Loader />
        </div>
    );

    if (user?.logged) {
        router = createBrowserRouter(
            [
                // Version
                {
                    path: '/environment/',
                    lazy: EnvView,
                },
                {
                    path: '/environment',
                    element: <Navigate to='/environment/' />,
                },
                // UI Routes
                {
                    element:
                        !user?.customer?.isLoaded || !user?.logisticProvider?.isLoaded ? (
                            <Suspense fallback={loader}>{loader}</Suspense>
                        ) : (
                            <Suspense fallback={loader}>
                                <AutoRefresh localStorageKey='externalConsoleVersion' />
                                <Outlet />
                            </Suspense>
                        ),
                    children: createRoutesFromObject(
                        getLoggedUserRoutes(user, features),
                        <AppLayout
                            breadcrumb={<SpacefillBreadcrumb />}
                            leftNav={<ExternalConsoleLeftNav />}
                        >
                            <Outlet />
                        </AppLayout>
                    ),
                },
            ],
            {
                basename: import.meta.env?.VITE_EXTERNAL_CONSOLE_ROUTER_BASE_NAME,
            }
        );
    } else {
        router = createBrowserRouter(
            [
                {
                    element: (
                        <Suspense fallback={<Fragment />}>
                            <Outlet />
                        </Suspense>
                    ),
                    children: createRoutesFromObject(
                        getUnloggedUserRoutes(),
                        <AppLayout
                            breadcrumb={<SpacefillBreadcrumb />}
                            leftNav={<ExternalConsoleLeftNav />}
                        >
                            <Outlet />
                        </AppLayout>
                    ),
                },
            ],
            {
                basename: import.meta.env?.VITE_EXTERNAL_CONSOLE_ROUTER_BASE_NAME,
            }
        );
    }

    return <RouterProvider router={router} />;
};

export { RouterContext };
